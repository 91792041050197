.App {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    font-family: Rubik;
}

.fill {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
}

.sessionButton {
    padding-left: 13px;
    padding-right: 13px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: black;
    border-radius: 10px;
    cursor: pointer;
    color: white;
    font-weight: bold;
    font-size: 17px;
}

html {
    background-color: #000000;
}